import React, { Component } from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { StyledCard } from '../styles/styled-components/ContextCard';

@withTranslation()
export default class ContextCard extends Component {

  render() {
    const { t } = this.props;
    const tCard = 'welcome.' + this.props.card;
    let link = '/newTicket?context=' + this.props.card + '&edit=true';
    return (
      <Col>
        <StyledCard>
          <div className="d-flex align-items-center justify-content-center py-3">
            <img className="picture" src={this.props.image} alt={t(tCard + '.image_alt')}/>
          </div>
          <div className="d-flex align-items-center justify-content-center py-3">
            <a className="btn btn-outline-secondary mr-4" href={link}>
              {t(tCard + '.link_title')}
            </a>
          </div>
          <div className="d-flex align-items-center justify-content-center py-3">
            <span>{t(tCard + '.description')}</span>
          </div>
        </StyledCard>
      </Col>
    );
  }
}

ContextCard.propTypes = {
  image: PropTypes.string,
  card: PropTypes.string,
  t: PropTypes.func, //HOC
};
