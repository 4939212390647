import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Row, Container, Col } from 'reactstrap';

import { HelpdeskWelcome } from '../styles/styled-components/welcomePage';
import ContextCardUnregistered from './ContextCardUnregistered';
import AccountImage from '../../assets/svg/start/account.svg';

@connect(
  state => ({
    loginStore: state.login.get('loginData'),
  }),
)
@withTranslation()
export default class LandingPageHeader extends Component {
  render() {
    const { t } = this.props;
    if (this.props.loginStore) {
      return (
        <HelpdeskWelcome className='helpdesk-welcome loggedin'>
          <div className="main">
            <div className="center">
              <h1>{ t('welcome.header.prefix') }</h1>
              <h1 className='welcome-header-helpdesk'>{ t('welcome.header.helpdesk') }</h1>
            </div>
          </div>
        </HelpdeskWelcome>
      );
    }
    else {
      return (
        <HelpdeskWelcome className='helpdesk-welcome'>
          <div className="main">
            <div className="center">
              <h1>{ t('welcome.header.title') }</h1>
              <h1 className='welcome-header-helpdesk'>{ t('welcome.header.helpdesk') }</h1>
              <div className='txt'>
                <div>
                  <h2>{ t('welcome.header.helpdesk_description') }</h2>
                </div>
              </div>
              <Container fluid="md">
                <Row>
                  <div className="mx-auto">
                    <Col>
                    </Col>
                  </div>
                </Row>
                <Row>
                  <div className="mx-auto">
                    <ContextCardUnregistered image={AccountImage} card={'account'}/>
                  </div>
                </Row>
              </Container>
            </div>
          </div>
        </HelpdeskWelcome>
      );
    }
  }
}

LandingPageHeader.propTypes = {
  t: PropTypes.func, //HOC
  loginStore: PropTypes.object, //HOC
};
