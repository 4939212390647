import * as React from 'react';
import { FormGroup, Button, Modal, ModalHeader, ModalBody, Label  } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withSendRequest } from '../../main/hoc/withSendRequest';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import Comments from './comments';
import Attachments from './attachments';
import ContextView from './contextView';
import Status from '../components/Status';


@connect(
  state => ({
    loginStore: state.login.get('loginData'),
  }),
)
@withSendRequest
@ErrorBoundary(props => props.t('view_ticket_component_name'))
@withTranslation()
export default class ViewTicket extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      statusDropdown: false,
    };
  }

  close = () => {
    if (this.props.reloadTickets)
      this.props.reloadTickets();
    this.props.onModalChange();
  };

  render() {
    const { t } = this.props;
    let summary = "";
    if ('key' in this.props.ticket) {
      summary = this.props.ticket['summary'];
    }
    let description = "";
    if ('key' in this.props.ticket) {
      description = this.props.ticket['description'];
      this.description = description;
    }
    const created = new Date(this.props.ticket['created']);
    const updated = new Date(this.props.ticket['updated']);

    return (
      <div>
        <Modal isOpen={this.props.modal} toggle={this.props.onModalChange} size="lg" >
          <ModalHeader toggle={this.props.onModalChange}><b>{ t('ticket') } { this.props.ticket['key'] }</b></ModalHeader>
          <ModalBody>
            <div>
              <div>
                <div className="d-flex align-items-center mb-3">
                  <span className='mr-2'><b>{t('edit_ticket_status')}: </b></span>
                  <Status color={this.props.ticket['status_color']} status={this.props.ticketStatus} right={true}/>
                </div>
                <hr />
                <label><b>{ t('details') }:</b></label>
                <FormGroup>
                  <Label>{t('created')}: {created.toLocaleString()}</Label>
                  <br/>
                  <Label>{t('updated')}: {updated.toLocaleString()}</Label>
                  <br/>
                  <ContextView ticket={this.props.ticket} t={t}/>
                </FormGroup>
              </div>
              <hr />
              <div className="form-group">
                <label htmlFor="summary"><b>{ t('ticket_summary') }:</b></label>
                <div>
                  <span>{ summary }</span>
                </div>
              </div>
              <hr />
              <div className="form-group mb-4">
                <label htmlFor="description"><b>{ t('ticket_description') }:</b></label>
                <div>
                  {description?.split('\n').map(line => line.trim()).map((line, indx) =>
                    <div key={indx}>{line}</div>
                  )}
                </div>
              </div>
              <Attachments attachments={this.props.ticket['attachments']} t={t} ticket_id={ String(this.props.ticket['id']) } />
              <hr className="mt-4"/>
              <Comments comments={this.props.ticket['comments']} t={t}
                attachments={this.props.ticket['comment_attachments']}
                ticket_id={ String(this.props.ticket['id']) }
              />
              <div className="d-flex flex-row-reverse">
                <div className="p-2">
                  <Button color="secondary" onClick={this.close}>
                    { t('edit_ticket_close') }
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}


ViewTicket.propTypes = {
  ticket: PropTypes.object,
  reloadTickets: PropTypes.func,
  sendRequest: PropTypes.object,
  t: PropTypes.func,
  loginStore: PropTypes.object, //HOC
  onModalChange: PropTypes.func,
  ticketStatus: PropTypes.string,
  modal: PropTypes.bool,
};

