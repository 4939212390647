import React, { Component } from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { AccountDescription, AccountHeader, StyledCard } from '../styles/styled-components/ContextCardUnregistered';

@withTranslation()
export default class ContextCardUnregistered extends Component {

  render() {
    const { t } = this.props;
    const tCard = 'welcome.unregistered.' + this.props.card;
    const link = '/newTicketUnregistered?context=' + this.props.card;
    return (
      <Col>
        <StyledCard>
          <div className="d-flex">
            <div className="p-2">
              <div className="d-flex align-items-center justify-content-center py-2">
                <img className="picture" src={this.props.image} alt={t(tCard + '.image_alt')}/>
              </div>
            </div>
            <div className="p-2 ml-5">
              <div>
                <AccountHeader>{t(tCard + '.title')}</AccountHeader>
                <AccountDescription>{t(tCard + '.description')}</AccountDescription>
                <div className="d-flex align-items-center justify-content-left py-2">
                  <a className="btn btn-outline-secondary mr-4" href={link}>
                    {t(tCard + '.link_title')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </StyledCard>
      </Col>
    );
  }
}

ContextCardUnregistered.propTypes = {
  image: PropTypes.string,
  card: PropTypes.string,
  t: PropTypes.func, //HOC
};
