import styled from 'styled-components';
import { Navbar } from 'reactstrap';

export const StyledNavbar = styled(Navbar)`
  .divider {
    height: 1px;
    width: 85%;
    margin: 5px auto;
    background-color: ${({ theme }) => theme.colors.text.grey4};
  }

  .space-link {
    color: ${({ theme }) => theme.colors.text.grey1};
  }

  .active-space-link {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  .icon {
    margin-right: 15px;
  }

  .alert-icon {
    margin-bottom: -8px;
  }

  .caret {
    margin-left: 5px;
    transition: transform .5s ease;
  }

  .navbar-brand {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 16px;
    margin-right: 25px;
  }
`;

export const HelpdeskLink = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #434343;
  flex: none;
  order: 2;
  flex-grow: 0;
  &:hover { color: #131313; };
`;

export const MyTicketsLink = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #106BEF;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin-left: 10px;
  &:hover { color: #0049B1; };
`;