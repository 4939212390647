import * as React from 'react';
import { Row, Col, FormGroup, Button, Spinner, Label, ListGroupItem, ListGroupItemText  } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Card } from 'reactstrap';

import { __env } from '../../envloader/index';
import { withSendRequest } from '../../main/hoc/withSendRequest';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import * as notify from '../../main/utils/notify';
import { getLogger } from '../../main/utils/logger';
import AddComment from './addComment';
import Comments from './comments';
import Attachments from './attachments';
import ContextView from './contextView';


function TransitionList(props) {
  const transitions = props.transitions;
  return (
    <DropdownMenu>
      {transitions.map((transition, index) =>
        <DropdownItem onClick={ props.changeValue } dropdownvalue={ transition['name'] } key={index}>{ transition['name'] }</DropdownItem>
      )}
    </DropdownMenu>
  );
}


TransitionList.propTypes = {
  transitions: PropTypes.array,
  changeValue: PropTypes.func,
};


function Description(props) {
  return (
    <div className="form-group">
      <ListGroupItem>
        <ListGroupItemText>
          { props.description }
        </ListGroupItemText>
      </ListGroupItem>
    </div>
  );
}


Description.propTypes = {
  description: PropTypes.string,
};


@connect(
  state => ({
    loginStore: state.login.get('loginData'),
  }),
)
@withSendRequest
@ErrorBoundary(props => props.t('edit_ticket_component_name'))
@withTranslation()
export default class EditTicketView extends React.Component {
  componentDidMount() {
    this.getEditingTicket();

  }

  getEditingTicket() {
    const ticketId = parseInt(this.props.match.params.ticketId);
    const { t } = this.props;
    const url = __env.TICKETS_API_URL + "api/tickets/" + ticketId + "/";
    this.props.sendRequest('get', url).then(response => {
      this.setState({
        ticket: response.data,
        ticketId: ticketId,
        ticketStatus: response.data.status
      });
    }, (error) => {
      notify.error(t('common:error'), t('try_later'));
      getLogger().error("tickets-ui", new Error(" Error during get the ticket (id: " + this.state.id + ")"));
    });
  }


  constructor(props) {
    super(props);

    this.state = {
      statusDropdown: false,
      modalAddComment: false,
      changeStatusDisabled: true
    };
    this.toggleStatus = this.toggleStatus.bind(this);
    this.handleModalAddComment = this.handleModalAddComment.bind(this);
    this.getEditingTicket = this.getEditingTicket.bind(this);
  }

  changeSummary = () => {
    const { t } = this.props;
    if (!this.summary.value || this.summary.value.trim() === '') {
      notify.error(t('common:error'), t('new_ticket_error_empty_summary'));
      return;
    }
    const data = {
      summary: this.summary.value
    };
    const url = __env.TICKETS_API_URL + "api/tickets/" + this.state.ticketId + '/';
    this.props.sendRequest('put', url, data).then(response => {
      notify.success(t('success'), t('edit_ticket_change_summary_success'));
    }).catch(error => {
      notify.error(t('common:error'), t('try_later'));
      getLogger().error("tickets-ui", new Error(" Error during change ticket's summary"));
    });
  };

  changeStatus = () => {
    const { t } = this.props;
    const data = {
      status: this.state.ticketStatus
    };
    const url = __env.TICKETS_API_URL + "api/tickets/" + this.state.ticketId + '/';
    this.props.sendRequest('put', url, data).then(response => {
      this.getEditingTicket();
      notify.success(t('success'), t('edit_ticket_change_status_success'));
    }).catch(error => {
      notify.error(t('common:error'), t('try_later'));
      getLogger().error("tickets-ui", new Error(" Error during change ticket's description"));
    });
  };

  handleModalAddComment() {
    this.setState({
      modalAddComment: !this.state.modalAddComment
    });
  }


  toggleStatus = () => {
    this.setState({
      statusDropdown: !this.state.statusDropdown
    });
  };

  changeStatusDropDownValue = (e) => {
    let newStatus = e.currentTarget.getAttribute("dropdownvalue");
    let disabled = true;
    if (newStatus !== this.state.ticket['status'])
      disabled = false;
    this.setState({
      ticketStatus: newStatus,
      changeStatusDisabled: disabled
    });
  };

  renderTicket() {
    const { t } = this.props;
    let summary = "";
    if ('summary' in this.state.ticket) {
      summary = this.state.ticket['summary'];
    }
    let description = "";
    if ('description' in this.state.ticket) {
      description = this.state.ticket['description'];
      this.description = description;
    }
    const created = new Date(this.state.ticket['created']);
    const updated = new Date(this.state.ticket['updated']);

    return (
      <div>
        <Card>
          <div>
            <h3>{t('ticket')} {this.state.ticket['key']}</h3>
          </div>
          <hr />
          <div>
            <label><b>{t('details')}:</b></label>
            <Row xs="3">
              <Col>
                <FormGroup>
                  <Label>{t('created')}: {created.toLocaleString()}</Label>
                  <br/>
                  <Label>{t('updated')}: {updated.toLocaleString()}</Label>
                  <br/>
                  <Label>{t('ticket_type')}: {this.state.ticket['type']}</Label>
                  <br/>
                  <ContextView ticket={this.state.ticket} t={t}/>
                </FormGroup>
              </Col>
              <Col>
              </Col>
              <Col>
                <FormGroup className="text-right">
                  <Label>{t('edit_ticket_status')}:</Label>
                  <Dropdown isOpen={this.state.statusDropdown} toggle={this.toggleStatus} id="status">
                    <DropdownToggle caret className="dropdown-toggle">
                      {this.state.ticketStatus}
                    </DropdownToggle>
                    <TransitionList transitions={this.state.ticket['transitions']} changeValue={this.changeStatusDropDownValue}/>
                  </Dropdown>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="form-group">
            <Button id="change-status" color="primary" onClick={this.changeStatus} className="float-right" disabled={this.state.changeStatusDisabled}>
              {t('edit_ticket_change_status')}
            </Button>
          </div>
          <hr />
          <div className="form-group">
            <label htmlFor="summary"><b>{t('ticket_summary')}:</b></label>
            <input type="text" className="form-control"
              defaultValue={summary}
              placeholder={t('new_ticket_summary_overview')}
              id="summary" ref={node => (this.summary = node)}/>
            <br/>
            <Button id="change-summary" color="primary" onClick={this.changeSummary} className="float-right">
              {t('edit_ticket_change_summary')}
            </Button>
          </div>
          <hr />
          <div className="form-group">
            <label htmlFor="description"><b>{t('ticket_description')}:</b></label>
            <Description description={ description } />
            <br/>
            <Attachments attachments={this.state.ticket['attachments']}
              ticket_id={String(this.state.ticket['id'])}
              t={t}/>
          </div>
          <hr />
          <div className="form-group">
            <Comments comments={this.state.ticket['comments']} attachments={this.state.ticket['comment_attachments']}
              ticket_id={String(this.state.ticket['id'])} t={t}
            />
          </div>
          <div className="d-flex flex-row-reverse mb-4">
            <div>
              <AddComment modal={this.state.modalAddComment}
                ticketId={this.state.ticketId}
                ticket={this.state.ticket}
                onModalChange={this.handleModalAddComment}
                reloadTicket={this.getEditingTicket}
              />
              <Button id="add-comment" color="primary" onClick={this.handleModalAddComment}>
                {t('edit_ticket_add_comment')}
              </Button>
            </div>
            <div>
              <a className='btn btn-outline-secondary mr-4' href={'/tickets'} >
                {t('cancel')}
              </a>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  render() {
    if (this.state.ticketId)
      return this.renderTicket();
    return (
      <div>
        <Spinner animation="border" role="status">
          Loading...
        </Spinner>
      </div>
    );
  }
}


EditTicketView.propTypes = {
  reloadTickets: PropTypes.func,
  sendRequest: PropTypes.object,
  t: PropTypes.func,
  loginStore: PropTypes.object, //HOC
  onModalChange: PropTypes.func,
  modal: PropTypes.bool,
  onAddCommentModalChange: PropTypes.bool,
  modalAddComment: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      ticketId: PropTypes.string,
    })
  }),
};

