import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../main/utils/i18nConfigProvider';
import StatusDot from './StatusDot';

export default class Status extends Component {
  render() {
    const color = this.props.color;
    let ticketStatus = this.props.status;
    ticketStatus = ticketStatus.toLowerCase();
    ticketStatus = ticketStatus.replace(/ /g,"_");
    ticketStatus = i18n.t('issue.status.' + ticketStatus);
    if (this.props.right) {
      return (
        <>
          <span className='mr-2'>{ticketStatus}</span>
          <StatusDot color={color}/>
        </>
      );
    }
    else {
      return (
        <>
          <StatusDot color={color}/>
          <span className='ml-2'>{ticketStatus}</span>
        </>
      );
    }
  }
}

Status.propTypes = {
  color: PropTypes.string,
  status: PropTypes.string,
  right: PropTypes.bool
};
