import { __env } from '../../envloader/index';

export const namespace = '@TICKETS/';

export const GET_TICKETS = namespace + 'GET_TICKETS';
export const GET_TICKET = namespace + 'GET_TICKET';
export const GET_CONTEXT = namespace + 'GET_CONTEXT';

export const getTickets = ({ sendRequest, text, page, sizePerPage }) => dispatch => {
  var url = __env.TICKETS_API_URL + "api/tickets";
  url = url + "?page=" + page + "&sizePerPage=" + sizePerPage;
  if (text) {
    url = url + "&text=" + text;
  }
  sendRequest("get", url)
    .then(response => {
      dispatch({
        type: GET_TICKETS,
        payload: response.data
      });
    });
};

export const getTicket = ({ sendRequest, id }) => dispatch => {
  var url = __env.TICKETS_API_URL + "api/tickets/" + id + "/";
  var data = new FormData();
  data.append('user', this.props.loginStore.get('username'));
  sendRequest("get", url, data)
    .then(response => {
      dispatch({
        type: GET_TICKET,
        payload: response.data
      });
    });
};


export const getContext = ({ sendRequest }) => dispatch => {
  const url = __env.TICKETS_API_URL + "api/context/";
  sendRequest("get", url)
    .then(response => {
      dispatch({
        type: GET_CONTEXT,
        payload: response.data.config
      });
    });
};
