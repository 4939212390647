import i18n from 'i18next';

const getCurrentLang = () => {
  return i18n.language || window.localStorage.i18nextLng || 'pl';
};

export const getLangTag = () => {
  if (getCurrentLang().substring(0, 2) === 'pl') {
    return 'label_pl';
  }
  return 'label_en';
};

export default getCurrentLang;