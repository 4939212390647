import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Spinner, Card } from 'reactstrap';
import { Link } from 'react-router-dom';

import { ErrorBoundary } from '../../main/hoc/errorboundary';
import { getTickets } from '../actions';
import { withSendRequest } from '../../main/hoc/withSendRequest';
import * as notify from '../../main/utils/notify';
import { getLogger } from '../../main/utils/logger';
import { TablePagination } from '../components/TablePagination';
import { __env } from '../../envloader/index';
import ViewTicket from './viewTicket';
import plusIcon from '../../assets/svg/icons/plus-2.svg';
import searchIcon from '../../assets/svg/icons/search.svg';
import { SearchForm, SearchButton, SearchInputContainer, SearchInput, SearchButtonIcon, Search } from '../styles/styled-components/SearchInput';
import { Tickets } from '../components/Tickets';


@connect(
  state => ({
    tickets: state.tickets,
    loginStore: state.login.get('loginData'),
  }),
  dispatch => {
    return bindActionCreators({
      getTickets: getTickets,
    }, dispatch);
  }
)
@ErrorBoundary(props => props.t('ticket_list_component_name'))
@withSendRequest
@withRouter
@withTranslation()
export default class TicketsList extends React.Component {
  componentDidMount() {
    this.reloadTickets();
  }

  reloadTickets() {
    let txt = null;
    if (this.text)
      txt = this.text.value;
    this.props.getTickets({ sendRequest: this.props.sendRequest, text: txt, page: this.state.page,
      sizePerPage: this.state.sizePerPage });
    this.setState({
      loading: true,
    });
  }

  reloadTicket() {
    const { t } = this.props;
    const url = __env.TICKETS_API_URL + "api/tickets/" + this.state.ticketId + "/";
    this.props.sendRequest('get', url).then(response => {
      this.setState({
        ticket: response.data,
      });
    }, (error) => {
      notify.error(t('common:error'), t('try_later'));
      getLogger().error("tickets-ui", new Error(" Error during delete the ticket (id: " + this.state.id + ") - " + error));
    });
  }

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      modalViewTicket: false,
      ticketId: 57,
      ticketStatus: "To do",
      ticket: {},
      text: null,
      page: 1,
      totalSize: 8,
      sizePerPage: 10
    };
    this.viewTicket = this.viewTicket.bind(this);
    this.search = this.search.bind(this);
  }

  handleModalViewTicket() {
    this.setState({
      modalViewTicket: !this.state.modalViewTicket
    });
  }

  viewTicket = (id) => {
    const { t } = this.props;
    const url = __env.TICKETS_API_URL + "api/tickets/" + id + "/";
    this.setState({
      ticketId: id
    });

    this.props.sendRequest('get', url).then(response => {
      this.setState({
        ticket: response.data,
        ticketStatus: response.data.status
      });
      this.handleModalViewTicket();
    }, (error) => {
      notify.error(t('common:error'), t('try_later'));
      getLogger().error("tickets-ui", new Error(" Error during delete the ticket (id: " + id + ") - " + error));
    });
  };

  editTicket = (id) => {
    this.props.history.push("/ticket/" + id);
  };

  search(e) {
    e.preventDefault();
    this.setState({
      totalSize: this.props.tickets.count,
      data: this.props.tickets.results,
    }, () => {
      this.reloadTickets();
    });
  }

  handleTableChange = (page, sizePerPage) => {
    if (sizePerPage !== this.state.sizePerPage)
      page = 1;
    const totalPages = Math.ceil(this.props.tickets.count / sizePerPage);
    if (page >= 1 && page <= totalPages) {
      this.setState(() => ({
        page: page,
        sizePerPage: sizePerPage,
      }), () => {
        this.reloadTickets();
      });
    }
  };

  render() {
    const { t } = this.props;
    if (this.props.tickets.results)
      return (
        <>
          <Card>
            <div className='d-flex justify-content-between align-items-center'>
              <div className="d-flex flex-column">
                <div className="p-2">
                  <h2 className="title">{t('ticket_list_my_tickets')}</h2>
                </div>
                <div className="p-2">
                  <p>{t('ticket_list_all_tickets')}: { this.props.tickets.count }</p>
                </div>
              </div>
              <Link to='/newTicket' className='btn btn-secondary text-decoration-none'>
                <div className='flex-center'>
                  <div className='flex-center'>
                    <img src={plusIcon} alt='plus icon' className='mr-3 w-15' />
                    <span>{ t('new_ticket') }</span>
                  </div>
                </div>
              </Link>
            </div>
            <SearchForm onSubmit={this.search}>
              <Search>
                <SearchInputContainer hideBorderLeft={false}>
                  <SearchButton>
                    <SearchButtonIcon src={searchIcon} onClick={this.search} alt={t('ticket_list_search_icon')}/>
                  </SearchButton>
                  <SearchInput
                    type='text'
                    aria-label={t('ticket_list_search_label')}
                    ref={node => (this.text = node)}
                    placeholder={t('ticket_list_search_text')}
                  />
                </SearchInputContainer>
              </Search>
            </SearchForm>
            <Tickets
              tickets={ this.props.tickets.results } t={ t } details={ this.viewTicket }
              edit={ this.editTicket }
            />
            <TablePagination
              tickets={ this.props.tickets.results } t={ t }
              currentPage={ this.state.page }
              pageSize={ this.state.sizePerPage }
              totalCount={ this.props.tickets.count }
              onPageChange={ this.handleTableChange }
            />
            <ViewTicket modal={this.state.modalViewTicket}
              ticket={this.state.ticket}
              onModalChange={this.handleModalViewTicket.bind(this)}
              reloadTickets={this.reloadTickets.bind(this)}
              ticketStatus={this.state.ticketStatus}
              reloadTicket={this.reloadTicket.bind(this)}
            />
          </Card>
        </>
      );
    else {
      return (
        <div>
          <div>
            <Spinner animation="border" role="status">
              Loading...
            </Spinner>
          </div>
        </div>
      );
    }
  }
}


TicketsList.propTypes = {
  tickets: PropTypes.object,
  history: PropTypes.object,
  sendRequest: PropTypes.object,
  getTickets: PropTypes.func,
  t: PropTypes.func,
  loginStore: PropTypes.object, //HOC
};
