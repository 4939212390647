import styled from 'styled-components';

export const StyledButton = styled.button`
  padding: 19px 19px;
  border-radius: 24px !important;
  border-width: 0;
  align-items: center;
  height: 36px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  outline: none !important;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;

  background: #106BEF !important;

  &:hover { background-color: #0D59C7 !important; };
  &:active { background-color: #0049B1 !important; };
  &:disabled { background-color: #777676 !important; };
`;
